form {
    width: 300px;
    margin: 20px auto;
    font-family: "Red Hat Display";
}
input[type=text], textarea {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    box-sizing: border-box;
    max-height: 65px;
}
input[type=number] {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    box-sizing: border-box;
    max-height: 65px;
}
input[type=email] {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    box-sizing: border-box;
    max-height: 65px;
}
input[type=submit] {
    width: 100%;
    background-color: #2B2B2B;
    outline: 2px solid #05AEAF;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    cursor: pointer;
}
input[type=submit]:hover {
    background-color: #05AEAF;
    outline: 2px solid #2B2B2B;
}
.row {
    padding: 0;
    margin: 0;
}

/* Responsive */
/* Extra grande (para pantallas grandes) */
@media only screen and (min-width: 1201px) {
}
/* Grande (para ordenadores de escritorio grandes) */
@media only screen and (min-width: 993px) and (max-width: 1200px) {
}
/* Medio grande (para tabletas en paisaje y algunos ordenadores de escritorio) */
@media only screen and (min-width: 769px) and (max-width: 992px) {
}
/* Medio (para retratos de tabletas y teléfonos móviles grandes) */
@media only screen and (min-width: 601px) and (max-width: 768px) {
}
/* Medio pequeño (para teléfonos móviles grandes) */
@media only screen and (min-width: 481px) and (max-width: 600px) {
}
/* Pequeño (para teléfonos móviles pequeños) */
@media only screen and (min-width: 321px) and (max-width: 480px) {
}
/* Extra pequeño (para teléfonos móviles muy pequeños) */
@media only screen and (max-width: 320px) {
}