/*Seccion Principal*/
.sect-principal {
    width: 100%;
    height: auto;
    background-color: #2a2a2a;
    color: white;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    box-shadow: 3px 3px 70px black;
}
.sect-principal .imagen {
    display: flex;
    justify-content: center;
    align-items: center;
}
.sect-principal .imagen img {
    width: 500px;
    height: 320px;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    box-shadow: 10px 10px 15px black;
    z-index: 2;
}
.sect-principal .row {
    position: relative;
    margin: 0;
    padding: 0 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    box-sizing: border-box;
}
/* Empiezo desde aqui */
.sect-principal .row .background {
    content: "";
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 1;
    filter: blur(15px);
    -webkit-filter: blur(15px);
}
.sect-principal .row.imagen-navidad .background {
    background-image: url("../images/navidad.png");
}
.sect-principal .row.imagen-dos .background {
    background-image: url("../images/6.png");
}
.sect-principal .row.imagen-tres .background {
    background-image: url("../images/7.png");
}
.sect-principal .row.imagen-cuatro .background {
    background-image: url("../images/8.png");
}
.sect-principal .row .caja header {
    font-family: "Oswald";
    font-size: 70px;
    text-transform: uppercase;
    margin: 0;
    padding: 15px 0;
    width: 100% !important;
    text-align: left !important;
    z-index: 2;
}
.sect-principal .row .caja p {
    line-height: 30px;
    font-size: 22px !important;
    margin: 0;
    padding: 15px 0;
    width: 100% !important;
    text-align: left !important;
    margin-bottom: 30px;
    font-family: "Red Hat Display";
    line-height: 35px !important;
    z-index: 2;
}
.sect-principal .row .caja a {
    padding: 5px;
    text-decoration: none;
    background-color: #0CABAE;
    color: white;
    font-weight: normal;
    text-transform: none;
    border-radius: 30px !important;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    z-index: -1;
    font-family: "Red Hat Display";
    z-index: 2;
}
.sect-principal .row .color-boton-info {
    padding: 10px 20px;
    text-decoration: none;
    background-color: #0CABAE;
    color: white;
    font-weight: bolder;
    text-transform: uppercase;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.sect-principal .row .color-boton-navidad {
    padding: 10px 20px !important;
    text-decoration: none;
    background-color: #FF4444 !important;
    color: black !important;
    font-weight: bolder !important;
    text-transform: uppercase;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.sect-principal .caja {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    margin: 0;
    padding: 0px;
}
.indicadores {
    display: flex;
    justify-content: center;
    width: 100%;
}
.circulo {
    height: 10px;
    width: 10px;
    margin: 0 5px;
    background-color: #02ADAE;
    border-radius: 50%;
    display: inline-block;
    cursor: none;
    z-index: 1;
}
.seleccionado {
    background-color: rgb(220, 220, 220);
}
.header-container {
    display: flex;
    align-items: center;
}
.header-container header {
    margin-right: 10px;
    font-size: 50px !important;
    font-weight: bold;
}
.header-container img {
    margin: 0;
    width: 80px;
    height: auto;
}
#titulo-imagen {
    width: 80px;
    height: auto;
}
.color-navidad {
    color: #FF4444;
}
/* Segunda Seccion */
.contenedor-video {
    min-height: 500px;
    padding: 20px;
    padding-top: 60px;
}
.contenedor-video video {
    width: 100%;
}
/* Tercera Seccion */
.tituloServicos {
    height: auto;
    padding: 20px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.tituloServicos h4 {
    color: #02ADAE;
    font-family: "Oswald";
    font-weight: bold;
}
.tituloServicos p {
    font-weight: bold;
    font-family: "Red Hat Display";
    font-size: 22px;
    width: 80%;
    text-align: center;
}
.sect-servicios{
    margin: 0;
    width: 100%;
    height: 600px;
    padding: 20px;
    display: flex;
}

.sect-servicios .row{
    width: 100%;
    margin: 0;
}
.sect-servicios a{
    color: white;
    text-decoration: none;
}
.sect-servicios .internet{
    height: 500px;
    padding: 10px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    margin: 0 10px 0 0;
    width: 25%;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    box-shadow: 0 0 15px 0 black;
    position: relative;
    z-index: 1;
    transition: all ease-in-out 0.5s;
    -webkit-transition: all ease-in-out 0.5s;
    -moz-transition: all ease-in-out 0.5s;
    -ms-transition: all ease-in-out 0.5s;
    -o-transition: all ease-in-out 0.5s;
}
.sect-servicios .internet h3{
    transform: texto;
    -webkit-transform: texto;
    -moz-transform: texto;
    -ms-transform: texto;
    -o-transform: texto;
}
@keyframes texto {
    to {
        transition: all ease-in 0.5s;
        -webkit-transition: all ease-in 0.5s;
        -moz-transition: all ease-in 0.5s;
        -ms-transition: all ease-in 0.5s;
        -o-transition: all ease-in 0.5s;
    }
}
.sect-servicios .internet::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("../images/67.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    z-index: -1;
    filter: brightness(20%);
    -webkit-filter: brightness(20%);
    transition: background-color 0.5s ease, filter 0.5s ease;
    -webkit-transition: background-color 0.5s ease, filter 0.5s ease;
    -moz-transition: background-color 0.5s ease, filter 0.5s ease;
    -ms-transition: background-color 0.5s ease, filter 0.5s ease;
    -o-transition: background-color 0.5s ease, filter 0.5s ease;
}
.sect-servicios .internet h3 {
    transition: transform 0.5s ease, color 0.5s ease;
}
.sect-servicios .internet .icono img {
    transition: opacity 0.5s ease;
}
.sect-servicios .internet:hover::before {
    background-image: none;
    background: transparent linear-gradient(180deg, #1FB2AD 0%, #105A57 100%) 0% 0% no-repeat padding-box;
    filter: brightness(100%);
    -webkit-filter: brightness(100%);
}
.sect-servicios .internet:hover h3 {
    transform: translateY(-10px);
}

.sect-servicios .internet:hover .icono img {
    opacity: 0;
}
.sect-servicios .ciberseguridad{
    background-color: black;
    height: 500px;
    padding: 10px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    width: 25%;
    margin: 0 10px 0 10px;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    box-shadow: 0 0 15px 0 black;
    position: relative;
    z-index: 1;
}
.sect-servicios .ciberseguridad h3 {
    transition: transform 0.5s ease, color 0.5s ease;
}
.sect-servicios .ciberseguridad .icono img {
    transition: opacity 0.5s ease;
}
.sect-servicios .ciberseguridad::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("../images/68.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    z-index: -1;
    filter: brightness(20%);
    -webkit-filter: brightness(20%);
    transition: background-color 0.5s ease, filter 0.5s ease;
}
.sect-servicios .ciberseguridad:hover::before {
    background-image: none;
    background: transparent linear-gradient(180deg, #505050 0%, #000000 100%) 0% 0% no-repeat padding-box;
    filter: brightness(100%);
    -webkit-filter: brightness(100%);
}
.sect-servicios .ciberseguridad:hover h3 {
    transform: translateY(-10px);
}

.sect-servicios .ciberseguridad:hover .icono img {
    opacity: 0;
}
.sect-servicios .telefonia{
    width: 25%;
    background-color: #2a2a2a;
    height: 500px;
    padding: 10px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    margin: 0 10px;
    margin: 0 0px 0 10px;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    box-shadow: 0 0 15px 0 black;
    position: relative;
    z-index: 1;
}
.sect-servicios .telefonia h3 {
    transition: transform 0.5s ease, color 0.5s ease;
}
.sect-servicios .telefonia .icono img {
    transition: opacity 0.5s ease;
}
.sect-servicios .telefonia::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("../images/69.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    z-index: -1;
    filter: brightness(20%);
    -webkit-filter: brightness(20%);
    transition: background-color 0.5s ease, filter 0.5s ease;
}
.sect-servicios .telefonia:hover::before {
    background-image: none;
    background: transparent linear-gradient(180deg, #1FB2AD 0%, #105A57 100%) 0% 0% no-repeat padding-box;
    filter: brightness(100%);
    -webkit-filter: brightness(100%);
}
.sect-servicios .telefonia:hover h3 {
    transform: translateY(-10px);
}

.sect-servicios .telefonia:hover .icono img {
    opacity: 0;
}
.sect-servicios img{
    width: 150px;
}
.sect-servicios .internet img{
    width: 230px;
}
.sect-servicios .telefonia img{
    width: 230px;
}
.sect-servicios h3{
    font-size: 350px;
    font-family: "Oswald";
    text-transform: uppercase;
    text-align: center;
    font-size: 30px !important;
}
.sect-servicios h3::selection{
    color: #02ADAE;
    background-color: #2a2a2a;
}
.sect-servicios .ciberseguridad:hover img {
    display: none;
}
.sect-servicios .internet:hover img {
    display: none;
}
.sect-servicios .telefonia:hover img {
    display: none;
}
.sect-servicios .ciberseguridad:hover p{
    display: block;
}
.sect-servicios .internet:hover p{
    display: block;
}
.sect-servicios .telefonia:hover p{
    display: block;
}
.sect-servicios .ciberseguridad:hover button{
    display: block;
}
.sect-servicios .internet:hover button{
    display: block;
}
.sect-servicios .telefonia:hover button{
    display: block;
}
.sect-servicios .ciberseguridad .texto{
    padding: 1px;
    font-size: 16px;
    text-align: center;
    display: none;
    margin-top: 40px;
    font-family: "Red Hat Display";
}
.sect-servicios .telefonia .texto{
    padding: 1px;
    font-size: 16px;
    text-align: center;
    margin-top: 40px;
    display: none;
    font-family: "Red Hat Display";
}
.sect-servicios .internet .texto{
    padding: 1px;
    font-size: 16px;
    text-align: center;
    display: none;
    margin-top: 40px;
    font-family: "Red Hat Display";
}
.sect-servicios button {
    background-color: #F3F3F4;
    padding: 10px 40px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    border: none;
    display: none;
    margin-bottom: 60px;
}
.sect-servicios button a {
    color: #02ADAE;
}
.sect-servicios p::selection{
    color: #02ADAE;
    background-color: #2a2a2a;
}



/* Responsive */
/* Primera Seccion */
/* Medio grande (para tabletas en paisaje y algunos ordenadores de escritorio) */
@media only screen and (min-width: 769px) and (max-width: 992px) {
    .sect-principal .row .caja p {
        font-size: 14px !important;
        margin: 0;
        padding: 15px 0;
        width: 100% !important;
        text-align: left !important;
        margin-bottom: 15px;
        font-family: "Red Hat Display";
        line-height: 28px !important;
        z-index: 2;
    }
}
/* Medio (para retratos de tabletas y teléfonos móviles grandes) */
@media only screen and (min-width: 601px) and (max-width: 768px) {
    .sect-principal .row {
        position: relative;
        margin: 0;
        padding: 0 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 500px;
        box-sizing: border-box;
    }
    .sect-principal .row .caja header {
        font-family: "Oswald";
        font-size: 30px;
        text-transform: uppercase;
        margin: 0;
        padding: 15px 0;
        width: 100% !important;
        text-align: left !important;
        z-index: 2;
    }
    .sect-principal .row .caja p {
        font-size: 14px !important;
        margin: 0;
        padding: 15px 0;
        width: 100% !important;
        text-align: left !important;
        margin-bottom: 15px;
        font-family: "Red Hat Display";
        line-height: 28px !important;
        z-index: 2;
    }
    .sect-principal .row .caja a {
        padding: 5px;
        text-decoration: none;
        background-color: #0CABAE;
        color: white;
        font-weight: normal;
        text-transform: none;
        border-radius: 30px !important;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        z-index: -1;
        font-family: "Red Hat Display";
        z-index: 2;
        font-size: 14px;
    }
    .sect-principal .row .color-boton-info {
        padding: 10px 20px;
        text-decoration: none;
        background-color: #0CABAE;
        color: white;
        font-weight: bolder;
        text-transform: uppercase;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
    }
    .sect-principal .row .color-boton-peru {
        padding: 10px 20px;
        text-decoration: none;
        background-color: #B20F20 !important;
        color: white;
        font-weight: bolder;
        text-transform: uppercase;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
    }
    .sect-principal .caja {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        margin: 0;
        padding: 0px;
    }

    .header-container header {
        margin-right: 10px;
        font-size: 45px !important;
        font-weight: bold;
    }
    .header-container img {
        margin: 0;
        width: 50px;
        height: auto;
    }
    #titulo-imagen {
        width: 50px;
        height: auto;
    }
}
/* Medio pequeño (para teléfonos móviles grandes) */
@media only screen and (min-width: 481px) and (max-width: 600px) {
    .sect-principal .imagen {
        display: none;
    }
    .sect-principal .row {
        position: relative;
        margin: 0;
        padding: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 500px;
        box-sizing: border-box;
    }
    .sect-principal .row .caja header {
        font-family: "Oswald";
        font-size: 30px;
        text-transform: uppercase;
        margin: 0;
        padding: 15px 0;
        width: 100% !important;
        text-align: left !important;
        z-index: 2;
    }
    .sect-principal .row .caja p {
        font-size: 16px !important;
        margin: 0;
        padding: 15px 0;
        width: 100% !important;
        text-align: left !important;
        margin-bottom: 15px;
        font-family: "Red Hat Display";
        line-height: 28px !important;
        z-index: 2;
    }
    .sect-principal .row .caja a {
        padding: 5px;
        text-decoration: none;
        background-color: #0CABAE;
        color: white;
        font-weight: normal;
        text-transform: none;
        border-radius: 30px !important;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        z-index: -1;
        font-family: "Red Hat Display";
        z-index: 2;
        font-size: 14px;
    }
    .sect-principal .row .color-boton-info {
        padding: 10px 20px;
        text-decoration: none;
        background-color: #0CABAE;
        color: white;
        font-weight: bolder;
        text-transform: uppercase;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
    }
    .sect-principal .row .color-boton-peru {
        padding: 10px 20px;
        text-decoration: none;
        background-color: #B20F20 !important;
        color: white;
        font-weight: bolder;
        text-transform: uppercase;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
    }
    .sect-principal .caja {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        margin: 0;
        padding: 0px;
    }

    .header-container header {
        margin-right: 10px;
        font-size: 30px !important;
        font-weight: bold;
    }
    .header-container img {
        margin: 0;
        width: 45px;
        height: auto;
    }
    #titulo-imagen {
        width: 45px;
        height: auto;
    }
}
/* Pequeño (para teléfonos móviles pequeños) */
@media only screen and (min-width: 321px) and (max-width: 480px) {
    .sect-principal .imagen {
        display: none;
    }
    .sect-principal .row {
        position: relative;
        margin: 0;
        padding: 0 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 500px;
        box-sizing: border-box;
    }
    .sect-principal .row .caja header {
        font-family: "Oswald";
        font-size: 30px;
        text-transform: uppercase;
        margin: 0;
        padding: 15px 0;
        width: 100% !important;
        text-align: left !important;
        z-index: 2;
    }
    .sect-principal .row .caja p {
        font-size: 16px !important;
        margin: 0;
        padding: 15px 0;
        width: 100% !important;
        text-align: left !important;
        margin-bottom: 15px;
        font-family: "Red Hat Display";
        line-height: 28px !important;
        z-index: 2;
    }
    .sect-principal .row .caja a {
        padding: 5px;
        text-decoration: none;
        background-color: #0CABAE;
        color: white;
        font-weight: normal;
        text-transform: none;
        border-radius: 30px !important;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        z-index: -1;
        font-family: "Red Hat Display";
        z-index: 2;
        font-size: 14px;
    }
    .sect-principal .row .color-boton-info {
        padding: 10px 20px;
        text-decoration: none;
        background-color: #0CABAE;
        color: white;
        font-weight: bolder;
        text-transform: uppercase;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
    }
    .sect-principal .row .color-boton-peru {
        padding: 10px 20px;
        text-decoration: none;
        background-color: #B20F20 !important;
        color: white;
        font-weight: bolder;
        text-transform: uppercase;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
    }
    .sect-principal .caja {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        margin: 0;
        padding: 0px;
    }

    .header-container header {
        margin-right: 10px;
        font-size: 35px !important;
        font-weight: bold;
    }
    .header-container img {
        margin: 0;
        width: 45px;
        height: auto;
    }
    #titulo-imagen {
        width: 45px;
        height: auto;
    }
}
/* Extra pequeño (para teléfonos móviles muy pequeños) */
@media only screen and (max-width: 320px) {
    .sect-principal {
        width: 100%;
        height: auto;
        background-color: #2a2a2a;
        color: white;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        box-shadow: 3px 3px 70px black;
    }
    .sect-principal .imagen {
        display: none;
    }

    .sect-principal .row {
        position: relative;
        margin: 0;
        padding: 0 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 500px;
        box-sizing: border-box;
    }
    .sect-principal .row .caja header {
        font-family: "Oswald";
        font-size: 30px;
        text-transform: uppercase;
        margin: 0;
        padding: 15px 0;
        width: 100% !important;
        text-align: left !important;
        z-index: 2;
    }
    .sect-principal .row .caja p {
        font-size: 14px !important;
        margin: 0;
        padding: 15px 0;
        width: 100% !important;
        text-align: left !important;
        margin-bottom: 15px;
        font-family: "Red Hat Display";
        line-height: 28px !important;
        z-index: 2;
    }
    .sect-principal .row .caja a {
        padding: 5px;
        text-decoration: none;
        background-color: #0CABAE;
        color: white;
        font-weight: normal;
        text-transform: none;
        border-radius: 30px !important;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        z-index: -1;
        font-family: "Red Hat Display";
        z-index: 2;
        font-size: 14px;
    }
    .sect-principal .row .color-boton-info {
        padding: 10px 20px;
        text-decoration: none;
        background-color: #0CABAE;
        color: white;
        font-weight: bolder;
        text-transform: uppercase;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
    }
    .sect-principal .row .color-boton-peru {
        padding: 10px 20px;
        text-decoration: none;
        background-color: #B20F20 !important;
        color: white;
        font-weight: bolder;
        text-transform: uppercase;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
    }
    .sect-principal .caja {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        margin: 0;
        padding: 0px;
    }
    .indicadores {
        display: flex;
        justify-content: center;
        width: 100%;
    }
    .circulo {
        height: 10px;
        width: 10px;
        margin: 0 5px;
        background-color: #02ADAE;
        border-radius: 50%;
        display: inline-block;
        cursor: none;
        z-index: 1;
    }
    .seleccionado {
        background-color: rgb(220, 220, 220);
    }
    .header-container {
        display: flex;
        align-items: center;
    }
    .header-container header {
        margin-right: 10px;
        font-size: 30px !important;
        font-weight: bold;
    }
    .header-container img {
        margin: 0;
        width: 45px;
        height: auto;
    }
    #titulo-imagen {
        width: 45px;
        height: auto;
    }
}

/* Seccion Video */
/* Extra grande (para pantallas grandes) */
@media only screen and (min-width: 1201px) {
}
/* Grande (para ordenadores de escritorio grandes) */
@media only screen and (min-width: 993px) and (max-width: 1200px) {
}
/* Medio grande (para tabletas en paisaje y algunos ordenadores de escritorio) */
@media only screen and (min-width: 769px) and (max-width: 992px) {
}
/* Medio (para retratos de tabletas y teléfonos móviles grandes) */
@media only screen and (min-width: 601px) and (max-width: 768px) {
}
/* Medio pequeño (para teléfonos móviles grandes) */
@media only screen and (min-width: 481px) and (max-width: 600px) {
    .contenedor-video {
        min-height: auto;
        padding: 20px;
        padding-top: 30px;
    }
}
/* Pequeño (para teléfonos móviles pequeños) */
@media only screen and (min-width: 321px) and (max-width: 480px) {
    .contenedor-video {
        min-height: auto;
        padding: 20px;
        padding-top: 30px;
    }
}
/* Extra pequeño (para teléfonos móviles muy pequeños) */
@media only screen and (max-width: 320px) {
    .contenedor-video {
        min-height: auto;
        padding: 20px;
        padding-top: 30px;
    }
}


/* Seccion Servicios */
/* Medio (para retratos de tabletas y teléfonos móviles grandes) */
@media only screen and (min-width: 601px) and (max-width: 768px) {
    .tituloServicos {
        height: auto;
        padding: 20px 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .tituloServicos h4 {
        font-size: 35px;
    }
    .tituloServicos p {
        font-size: 14px;
        width: 80%;
    }
    .sect-servicios{
        height: auto;
        margin: 0;
    }
    .sect-servicios .row{
        gap: 10px;
        flex-direction: row;
        padding: 0;
        margin: 0;
    }
    .sect-servicios a{
        color: white;
        text-decoration: none;
    }
    .sect-servicios .internet{
        width: 32%;
        margin: 0;
    }
    .sect-servicios .internet h3 {
        font-size: 25px !important;
    }
    .sect-servicios .texto {
        font-size: 12px !important;
    }
    .sect-servicios .ciberseguridad{
        width: 32%;
        margin: 0;
    }
    .sect-servicios .ciberseguridad h3 {
        font-size: 25px !important;
    }
    .sect-servicios .telefonia{
        width: 32%;
        margin: 0;
    }
    .sect-servicios .telefonia h3 {
        font-size: 25px !important;
    }
}
/* Medio pequeño (para teléfonos móviles grandes) */
@media only screen and (min-width: 481px) and (max-width: 600px) {
    .tituloServicos {
        height: auto;
        padding: 20px 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .tituloServicos h4 {
        font-size: 35px;
    }
    .tituloServicos p {
        font-size: 14px;
        width: 80%;
    }
    .sect-servicios{
        height: auto;
        margin: 0;
    }
    .sect-servicios .row{
        gap: 12px;
        flex-direction: row;
        padding: 0;
        margin: 0;
    }
    .sect-servicios a{
        color: white;
        text-decoration: none;
    }
    .sect-servicios .internet{
        width: 48%;
        margin: 0;
    }
    .sect-servicios .internet h3 {
        font-size: 25px !important;
    }
    .sect-servicios .texto {
        font-size: 12px !important;
    }
    .sect-servicios .ciberseguridad{
        width: 48%;
        margin: 0;
    }
    .sect-servicios .ciberseguridad h3 {
        font-size: 25px !important;
    }
    .sect-servicios .telefonia{
        width: 50%;
        margin: 0;
    }
    .sect-servicios .telefonia h3 {
        font-size: 25px !important;
    }
    
}
/* Pequeño (para teléfonos móviles pequeños) */
@media only screen and (min-width: 321px) and (max-width: 480px) {
    .tituloServicos {
        height: auto;
        padding: 20px 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .tituloServicos h4 {
        font-size: 35px;
    }
    .tituloServicos p {
        font-size: 14px;
        width: 80%;
    }
    .sect-servicios{
        height: auto;
        margin: 0;
    }
    .sect-servicios .row{
        gap: 12px;
        flex-direction: row;
        padding: 0;
        margin: 0;
    }
    .sect-servicios a{
        color: white;
        text-decoration: none;
    }
    .sect-servicios .internet{
        width: 48%;
        margin: 0;
    }
    .sect-servicios .internet h3 {
        font-size: 22px !important;
    }
    .sect-servicios .texto {
        font-size: 12px !important;
    }
    .sect-servicios .ciberseguridad{
        width: 48%;
        margin: 0;
    }
    .sect-servicios .ciberseguridad h3 {
        font-size: 22px !important;
    }
    .sect-servicios .telefonia{
        width: 50%;
        margin: 0;
    }
    .sect-servicios .telefonia h3 {
        font-size: 22px !important;
    }
    
}
/* Extra pequeño (para teléfonos móviles muy pequeños) */
@media only screen and (max-width: 320px) {
    .tituloServicos {
        height: auto;
        padding: 20px 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .tituloServicos h4 {
        font-size: 24px;
    }
    .tituloServicos p {
        font-size: 12px;
        width: 80%;
    }
    .sect-servicios{
        height: auto;
    }
    .sect-servicios .row{
        gap: 20px;
    }
    .sect-servicios a{
        color: white;
        text-decoration: none;
    }
    .sect-servicios .internet{
        width: 100%;
    }
    .sect-servicios .ciberseguridad{
        width: 100%;
    }
    .sect-servicios .telefonia{
        width: 100%;
    }
    
}