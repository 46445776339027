/* Componente De Internet */
/* Primera Seccion */
.sect-pri {
    height: 450px;
    position: relative;
    margin: 0;
    padding: 0;
    box-shadow: 0 0 55px black;
}
.seccionInternet::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("../images/61.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    z-index: -1;
    filter: brightness(30%);
    -webkit-filter: brightness(30%);
}
.sect-pri .row {
    height: 100%;
    margin: 0;
    padding: 0;
}
.sect-pri header {
    font-size: 65px;
    font-family: "Oswald";
    font-weight: bold;
}
.sect-pri .texto {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    gap: 20px;
    padding-left: 60px;
    color: white;
    font-family: "Red Hat Display";
    font-size: 18px;
    font-weight: 400;
}
.sect-pri p {
    width: 100%;
}
.sect-pri .img {
    display: flex;
    justify-content: center;
    align-items: center;
}
.sect-pri .img img {
    width: 500px;
    height: 350px;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    box-shadow: 10px 10px 15px black;
}
/* Segunda Seccion */
.sect-secc {
    background-color: #2a2a2a;
    color: white;
    padding: 0;
    height: auto;
    padding-bottom: 100px;
}
.sect-secc header {
    width: 100%;
    text-align: center;
    font-family: "Oswald";
    font-weight: bold;
    font-size: 60px;
    color: #02ADAE;
    padding: 50px 0 20px 0;
}
.sect-secc .row {
    margin: 0;
    width: 100%;
    height: 500px;
    
}
.sect-secc .texto {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 50px;
}
.sect-secc .texto ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 35px;
}
.sect-secc li {
    list-style: circle;
    font-family: "Red Hat Display";
    font-size: 18px;
    font-weight: 400;
}
.sect-secc .img {
    background-image: url("../images/36.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    margin-bottom: 100px;
    box-shadow: -10px 10px 15px black;
}
/* Tercera Seccion */
.sect-ter {
    height: auto;
    margin: 0;
    padding: 0;
    box-shadow: 0 0 30px black;
}
.sect-ter .titulo {
    margin: 0;
    padding: 0;
    padding: 30px 40px;
}
h1 {
    font-family: "Oswald";
    font-weight: bold;
    font-size: 60px;
    color: #02ADAE;
}
.sect-ter .contenedorInfo {
    height: 450px;
    width: 100%;
    margin: 0;
}
.sect-ter .contenedorInfo .texto {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 30px;
    background-color: #FFFFFF;
}
.sect-ter .contenedorInfo .texto ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 10px;
    font-family: "Red Hat Display";
    font-size: 18px;
}
.sect-ter .contenedorInfo .img {
    position: relative;
    display: flex;
    text-align: center;
    justify-content: center;
    transition: all ease-in-out 0.5s;
    -webkit-transition: all ease-in-out 0.5s;
    -moz-transition: all ease-in-out 0.5s;
    -ms-transition: all ease-in-out 0.5s;
    -o-transition: all ease-in-out 0.5s;
    z-index: 1;
}
.sect-ter .contenedorInfo .img::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../images/37.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
    transition: all ease-in-out 0.5s;
    -webkit-transition: all ease-in-out 0.5s;
    -moz-transition: all ease-in-out 0.5s;
    -ms-transition: all ease-in-out 0.5s;
    -o-transition: all ease-in-out 0.5s;
}
.sect-ter .contenedorInfo:hover .texto{
    background-color: #F8F8F8;
}
.sect-ter .contenedorInfo:hover .img::before{
    filter: brightness(30%);
    -webkit-filter: brightness(30%);
}
.sect-ter .contenedorInfo .img h2 {
    color: white;
    font-size: 50px;
    font-family: "Oswald";
    font-weight: bold;
    position: absolute;
    top: 30%;
    width: 60%;
    z-index: 1;
}


/* Seccion Ciberseguridad */
/* Primera Seccion */
.seccionCiberseguridad::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("../images/62.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    z-index: -1;
    filter: brightness(50%);
    -webkit-filter: brightness(50%);
}
/* Segunda Seccion */
.sectt-sec {
    color: black;
    padding: 0;
    height: auto;
    padding-bottom: 100px;
}
.sectt-sec header {
    width: 100%;
    text-align: center;
    font-family: "Oswald";
    font-weight: bold;
    font-size: 60px;
    color: #02ADAE;
    padding: 50px 0 20px 0;
}
.sectt-sec .row {
    margin: 0;
    width: 100%;
    height: 500px;
    
}
.sectt-sec .texto {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 50px;
}
.sectt-sec .texto ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 35px;
}
.sectt-sec li {
    list-style: circle;
    font-family: "Red Hat Display";
    font-size: 18px;
    font-weight: 400;
}
.sectt-sec .img {
    background-image: url("../images/38.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    margin-bottom: 100px;
    box-shadow: -5px 5px 10px black;
}
/* Tercera Seccion */
.sect-ter {
    height: auto;
    margin: 0;
    padding: 0;
}
.sect-ter .titulo {
    margin: 0;
    padding: 0;
    padding: 30px 40px;
    float: left;
}
.sect-ter .titulo h1 {
    font-family: "Oswald";
    font-weight: bold;
    font-size: 60px;
    color: #02ADAE;
}
.sect-ter .carrusel {
    margin: 0;
    padding: 0;
    height: 300px;
}
.carousel{
    background-color: rgba(220, 220, 220, 0.2);
    height: 300px;
    padding: 20px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.contenedor-carusel img{
    width: 70%;
    padding: 0;
    text-align: center;
    filter: grayscale(100%);
}
.contenedor-carusel img:hover{
    filter: grayscale(0%);
}
.col-carusel{
    margin: 0;
    padding: 0;
    text-align: center;
}
.carousel #flecha-izq{
    position: absolute;
    left: 0px;
}
.carousel #flecha-der{
    position: absolute;
    right: 0px;
}



/* Seccion Telefonia */
/* Primera Seccion */
.seccionTelefonia::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("../images/63.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    z-index: -1;
    filter: brightness(50%);
    -webkit-filter: brightness(50%);
}
/* Segunda Seccion */
.ssect-sec {
    background-color: #2a2a2a;
    color: white;
    padding: 0;
    height: auto;
    padding-bottom: 100px;
}
.ssect-sec header {
    width: 100%;
    text-align: center;
    font-family: "Oswald";
    font-weight: bold;
    font-size: 60px;
    color: #02ADAE;
    padding: 50px 0 20px 0;
}
.ssect-sec .row {
    margin: 0;
    width: 100%;
    height: 500px;
    
}
.ssect-sec .texto {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 50px;
}
.ssect-sec .texto ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 20px;
}
.ssect-sec li {
    list-style: circle;
    font-family: "Red Hat Display";
    font-weight: 400;
    font-size: 18px;
}
.ssect-sec .img {
    background-image: url("../images/39.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    margin-bottom: 100px;
    box-shadow: -10px 10px 15px black;
}


/* Responsive */
/* Seccion Partners */
@media screen and (max-width: 768px) {
    .carousel{
        height: auto;
        padding: 10px;
        margin: 1rem 0;
    }
    .contenedor-carusel img{
        width: 80%;
    }
    .sect-ter .titulo h1 {
        font-size: 45px;
    }
}
@media screen and (max-width: 420px) {
    .carousel{
        height: auto;
        padding: 10px;
        margin: 1rem 0;
    }
    .contenedor-carusel img{
        width: 80%;
    }
    .sect-ter .titulo h1 {
        font-size: 30px;
    }
}
/* Extra grande (para pantallas grandes) */
@media only screen and (min-width: 1201px) {
    /* Apartado Internet */
    .sect-pri header{
        font-size: 40px !important; /*50px*/
    }
    .sect-pri p{
        font-size: 17px;
    }
    .sect-secc header{
        font-size: 50px;
    }
    .sect-secc .row{
        margin-top: 60px;
    }
    .sect-ter .contenedorInfo{
        margin-bottom: 100px;
    }
    .sect-ter .carousel{
        margin: 0;
        padding: 0;
    }
    .sect-secc .row .img {
        margin-left: 100px;
    }
    /* Apartado Ciberseguridad */
    .sectt-sec header{
        font-size: 60px !important;
    }
    .sectt-sec .row{
        margin-top: 40px;
    }
    .sectt-sec .row .texto li{
        font-size: 19px;
    }
    .sectt-sec .row .img {
        margin-left: 105px;
    }
    /* Apartado Telefonio */
    .ssect-sec .row .img {
        margin-left: 96px;
    }
}

/* Grande (para ordenadores de escritorio grandes) */
@media only screen and (min-width: 993px) and (max-width: 1200px) {
    /* Apartado Internet */
    .sect-pri header{
        font-size: 40px !important; /*50px*/
    }
    .sect-pri p{
        font-size: 17px;
    }
    .sect-secc header{
        font-size: 50px;
    }
    .sect-secc .row{
        margin-top: 60px;
    }
    .sect-ter .contenedorInfo{
        margin-bottom: 100px;
    }
    .sect-ter .carousel{
        margin: 0;
        padding: 0;
    }
    .sect-secc .row .img {
        margin-left: 100px;
    }
    /* Apartado Ciberseguridad */
    .sectt-sec header{
        font-size: 60px !important;
    }
    .sectt-sec .row{
        margin-top: 40px;
    }
    .sectt-sec .row .texto li{
        font-size: 18px;
    }
    .sectt-sec .row .img {
        margin-left: 100px;
    }
    /* Apartado Telefonia */
    .ssect-sec .row{
        margin-top: 40px;
    }
    .ssect-sec .row .img {
        margin-left: 96px;
    }
}

/* Medio grande (para tabletas en paisaje y algunos ordenadores de escritorio) */
@media only screen and (min-width: 769px) and (max-width: 992px) {
    /* Apartado Internet */
    .sect-pri header{
        font-size: 40px !important; /*50px*/
    }
    .sect-pri p{
        font-size: 17px;
    }
    .sect-secc header{
        font-size: 50px;
    }
    .sect-secc .row{
        margin-top: 60px;
    }
    .sect-secc .row .img {
        margin-left: 80px;
    }
    .sect-ter .contenedorInfo{
        margin-bottom: 100px;
    }
    .sect-ter .carousel{
        margin: 0;
        padding: 0;
    }
    /* Apartado Ciberseguridad */
    .sectt-sec header{
        font-size: 50px !important;
    }
    .sectt-sec .row{
        margin-top: 40px;
    }
    .sectt-sec .row .img {
        margin-left: 82px;
    }
    .sectt-sec .row .texto li{
        font-size: 15px;
    }
    /* Apartado Telefonia */
    .ssect-sec header{
        font-size: 50px !important;
    }
    .ssect-sec .row{
        margin-top: 40px;
    }
    .ssect-sec .row .img {
        margin-left: 82px;
    }
    .ssect-sec .row .texto li{
        font-size: 15px;
    }
}

/* Medio (para retratos de tabletas y teléfonos móviles grandes) */
@media only screen and (min-width: 601px) and (max-width: 768px) {
    /* Apartado Internet */
    .sect-secc .row {
        height: 600px;
    }
    .sect-pri header{
        font-size: 40px !important; /*50px*/
    }
    .sect-pri p{
        font-size: 15px !important;
    }
    .sect-secc header{
        font-size: 45px;
        margin-bottom: 10px;
    }
    .sect-secc .row{
        margin-top: 80px;
    }
    .sect-secc .row .img {
        margin-left: 60px;
    }
    .sect-ter .contenedorInfo{
        margin-bottom: 200px;
    }
    .sect-ter .carousel{
        margin: 0;
        padding: 0;
    }
    /* Apartado Ciberseguridad */
    .sectt-sec header{
        font-size: 45px !important;
    }
    .sectt-sec .row{
        margin-top: 30px;
    }
    .sectt-sec .row .img {
        margin-left: 64px;
    }
    .sectt-sec .row .texto li{
        font-size: 14px;
    }
    /* Apartado Telefonia */
    .ssect-sec header{
        font-size: 50px !important;
    }
    .ssect-sec .row{
        margin-top: 40px;
    }
    .ssect-sec .row .img {
        margin-left: 64px;
    }
    .ssect-sec .row .texto li{
        font-size: 15px;
    }
}

/* Medio pequeño (para teléfonos móviles grandes) */
@media only screen and (min-width: 481px) and (max-width: 600px) {
    /* Apartado Internet */
    .sect-pri header{
        font-size: 40px !important; /*50px*/
    }
    .sect-pri p{
        font-size: 20px !important;
    }
    .sect-secc header{
        font-size: 40px;
    }
    .sect-secc .row{
        margin-top: 100px;
        height: auto;
    }
    .sect-secc .texto{
        flex: 0 0 100%; /* La columna de texto ocupará todo el ancho */
        max-width: 100%;
        padding: 20px;
    }
    .sect-secc .img{
        display: none;
    }
    .sect-secc .row li{
        font-size: 18px;
    }
    .sect-ter .img h2{
        display: none;
    }
    .sect-ter .contenedorInfo{
        margin-bottom: 100px;
    }
    .sect-ter .carousel{
        margin: 0;
        padding: 0;
    }
    /* Apartado Ciberseguridad */
    .sect-pri p {
        font-size: 18px !important;
    }
    .sectt-sec header{
        font-size: 45px !important;
    }
    .sectt-sec .row{
        margin-top: 30px;
    }
    .sectt-sec .texto{
        flex: 0 0 100%; /* La columna de texto ocupará todo el ancho */
        max-width: 100%;
        padding: 20px;
    }
    .sectt-sec .img{
        display: none;
    }
    .sectt-sec .row .texto li{
        font-size: 15px;
    }
    .sectt-sec .row{
        margin-bottom: 20px;
    }
    /* Apartado Telefonia */
    .ssect-sec header{
        font-size: 50px !important;
    }
    .ssect-sec .row{
        margin-top: 40px;
        margin-bottom: 0px;
    }
    .ssect-sec .texto{
        flex: 0 0 100%; /* La columna de texto ocupará todo el ancho */
        max-width: 100%;
        padding: 20px;
    }
    .ssect-sec .img{
        display: none;
    }
    .ssect-sec .row .texto li{
        font-size: 16px;
    }
}

/* Pequeño (para teléfonos móviles pequeños) */
@media only screen and (min-width: 321px) and (max-width: 480px) {
    /* Apartado Internet */
    .sect-pri header{
        font-size: 35px !important; /*50px*/
    }
    .sect-pri p{
        text-align: justify;
        font-size: 15px !important;
    }
    .sect-secc header{
        font-size: 35px;
    }
    .sect-secc .row{
        margin-top: 50px;
    }
    .sect-secc .texto{
        flex: 0 0 100%; /* La columna de texto ocupará todo el ancho */
        max-width: 100%;
        padding: 20px;
    }
    .sect-secc .img{
        display: none;
    }
    .sect-ter .contenedorInfo .img {
        display: none;
    }
    .sect-secc .row li{
        font-size: 15px;
    }
    .sect-ter .img h2{
        display: none;
    }
    .sect-ter .titulo{
        font-size: 45px;
    }
    .sect-ter .contenedorInfo li{
        font-size: 15px
    }
    .sect-ter .contenedorInfo{
        margin-bottom: none;
    }
    .sect-ter .carousel{
        margin: 0;
        padding: 0;
    }
    /* Apartado Ciberseguridad */
    .sectt-sec header{
        font-size: 30px !important;
    }
    .sectt-sec .row{
        margin-top: 80px;
    }
    .sectt-sec .texto{
        flex: 0 0 100%; /* La columna de texto ocupará todo el ancho */
        max-width: 100%;
        padding: 20px;
    }
    .sectt-sec .img{
        display: none;
    }
    .sectt-sec .row .texto li{
        font-size: 15px;
    }
    .sectt-sec .row{
        margin-bottom: 20px;
    }
    /* Apartado Telefonia */
    .ssect-sec header{
        font-size: 40px !important;
    }
    .ssect-sec .row{
        margin-top: 30px;
        margin-bottom: 0px;
    }
    .ssect-sec .texto{
        flex: 0 0 100%; /* La columna de texto ocupará todo el ancho */
        max-width: 100%;
        padding: 20px;
    }
    .ssect-sec .img{
        display: none;
    }
    .ssect-sec .row .texto li{
        font-size: 14px;
    }
}

/* Extra pequeño (para teléfonos móviles muy pequeños) */
@media only screen and (max-width: 320px){
    /* Apartado Internet */
    .sect-pri header{
        font-size: 35px !important;
    }
    .sect-pri p{
        text-align: justify;
        font-size: 14px !important;
    }
    .sect-secc header{
        font-size: 30px;
    }
    .sect-secc .row{
        margin-top: 80px;
    }
    .sect-secc .texto{
        flex: 0 0 100%; /* La columna de texto ocupará todo el ancho */
        max-width: 100%;
        padding: 20px;
    }
    .sect-secc .img{
        display: none;
    }
    .sect-secc .row li{
        font-size: 12px;
    }
    .sect-ter .img h2{
        display: none;
    }
    .sect-ter .titulo{
        font-size: 40px;
    }
    .sect-ter .contenedorInfo li{
        font-size: 13px
    }
    .sect-ter .contenedorInfo{
        margin-bottom: none;
    }
    .sect-ter .carousel{
        margin: 0;
        padding: 0;
    }
    /* Apartado Ciberseguridad */
    .seccionCiberseguridad header{
        font-size: 25px !important;
    }
    .seccionCiberseguridad p{
        font-size: 14px !important;
    }
    .sectt-sec header{
        font-size: 28px !important;
    }
    .sectt-sec .row{
        margin-top: 90px;
    }
    .sectt-sec .texto{
        flex: 0 0 100%; /* La columna de texto ocupará todo el ancho */
        max-width: 100%;
        padding: 20px;
    }
    .sectt-sec .img{
        display: none;
    }
    .sectt-sec .row .texto li{
        font-size: 13px;
    }
    .sectt-sec .row{
        margin-bottom: 20px;
    }
    /* Apartado Telefonia */
    .ssect-sec header{
        font-size: 28px !important;
    }
    .ssect-sec .row{
        margin-top: 70px;
        margin-bottom: 10px;
    }
    .ssect-sec .texto{
        flex: 0 0 100%; /* La columna de texto ocupará todo el ancho */
        max-width: 100%;
        padding: 20px;
    }
    .ssect-sec .img{
        display: none;
    }
    .ssect-sec .row .texto li{
        font-size: 13px;
    }
}