/* Primera Seccion */
.sect-pri {
    height: 450px;
    position: relative;
    margin: 0;
    padding: 0;
    box-shadow: 0 0 55px black;
}
.sect-pri button {
    background-color: #02ADAE;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    padding: 5px;
    border: none;
    border: none;
}
.sect-pri a {
    color: white;
    text-decoration: none;
}
.seccionNosotros::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("../images/65.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    z-index: -1;
    filter: brightness(50%);
    -webkit-filter: brightness(50%);
}
.sect-pri .row {
    height: 100%;
    margin: 0;
    padding: 0;
}
.sect-pri header {
    font-size: 65px;
    font-family: "Oswald";
    font-weight: bold;
}
.sect-pri .texto {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    gap: 20px;
    padding-left: 60px;
    color: white;
    font-family: "Red Hat Display";
    font-size: 20px;
    font-weight: 400;
}
.sect-pri p {
    width: 100%;
}
.sect-pri .img {
    display: flex;
    justify-content: center;
    align-items: center;
}
.sect-pri .img img {
    width: 500px;
    height: 350px;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    box-shadow: 10px 10px 15px black;
}
#titulo-imagen {
    width: 80px; 
    height: auto;
}
.header-container {
    display: flex;
    align-items: center;
}
/* Segunda Seccion */
.sect-cuarta {
    height: auto;
    padding: 0;
    margin-top: 20px;
}
.sect-cuarta .row {
    padding: 0;
    margin: 0;
    width: 100%;
    height: auto;
}
.sect-cuarta .row .info {
    padding: 20px 40px;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    gap: 10px;
    font-size: 18px;
    font-family: "Red Hat Display";
}
.sect-cuarta h1 {
    font-size: 50px;
    color: #0CABAE;
    font-family: "Oswald";
    font-weight: bold;
    padding: 20px 40px;
}
.sect-cuarta .row .imagenes {
    padding: 0;
    background-image: url("../images/NOSOTROS.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: -10px 10px 15px black;
}
/* Tercera Seccion */
.sect-quinta {
    height: auto;
    padding: 0;
    margin-top: 60px;
}
.sect-quinta h3 {
    color: #02ADAE;
    font-family: "Oswald";
    font-weight: bold;
    font-size: 30px;
}
.sect-quinta .row {
    padding: 0;
    margin: 0;
    width: 100%;
    height: auto;
}
.sect-quinta .row .info {
    padding: 20px 40px;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    gap: 10px;
    font-size: 18px;
    font-family: "Red Hat Display";
}
.sect-quinta .row .info h1 {
    font-size: 50px;
    color: #0CABAE;
    font-family: "Oswald";
    font-weight: bold;
}
.sect-quinta .row .imagenes {
    padding: 0;
}
.sect-quinta .row .imagenes div:nth-child(2){
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sect-quinta .row .imagenes div img{
    height: 290px;
    width: 290px;
}
.sect-quinta .row .imagenes div:nth-child(1){
    height: 50%;
    background-image: url("../images/70.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 10px 10px 15px black;
    margin-bottom: 15px;
}
/* Cuarta Seccion */
.sect-es{
    margin: 0px;
    padding: 30px 0;
    max-width: 100%;
    height: auto;
    background-color: #2e2e2e;
    box-shadow: 0 0 20px gray;
}
.sect-firts, .sect-sec{
    height: 50%;
    margin: 0px;
    padding: 0px;
}
.sect-col-firts, .sect-col-sec{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin: 0px;
}
.sect-es .icono{
    font-size: 60px;
    color: #0CABAE;
    padding: 10px 0px;
    text-align: right;
    margin: 0px;
    transition: 0.5s ease-in-out;
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -ms-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
}
.sect-es .icono:hover{
    font-size: 100px;
}
.sect-es .texto{
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    padding: 10px;
    font-family: "Red Hat Display";
    color: white;
    margin: 0px;
}
/* Quinta Seccion */
.seccion-principios{
    width: 100%;
    max-height: 800px;
    margin: auto;
    padding: 20px;
    border-top: 2px solid #0CABAE;
    background-color: rgba(220, 220, 220, 0.2);
}
.seccion-principios .titulo-an{
    margin: 10px 0;
    padding: 20px;
    font-family: "Oswald";
    font-weight: bold;
    font-size: 50px;
    color: #02ADAE;
    text-transform: uppercase;
    width: 100%;
}
.overlay{
    transition: all 0.3s ease;
    opacity: 0;
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}
.overlay p{
    margin-bottom: 0;
    font-family: 'Oswald' !important;
    text-transform: uppercase;
    z-index: 1;
    color: white;
    font-size: 28px;
    font-weight: 700;
}
.card{
    position: relative;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    box-shadow: 5px 0px 15px black;
    min-height: 500px;
}
.card-body{
    background-color: #2e2e2e;
    color: white;
}
.card-body .card-text {
    font-family: "Red Hat Display";
    font-size: 16px;
    text-align: center;
}
.card:hover img{
    filter: brightness(50%);
    -webkit-filter: brightness(50%);
}
.card:hover .overlay{
    opacity: 1;
}

/* Responsive */
/* Encabezado Nosotros Primera Seccion */
/* Medio grande (para tabletas en paisaje y algunos ordenadores de escritorio) */
@media only screen and (min-width: 769px) and (max-width: 992px) {
    .sect-pri .img img {
        width: 380px !important;
        height: auto;
    }
}
/* Medio (para retratos de tabletas y teléfonos móviles grandes) */
@media only screen and (min-width: 601px) and (max-width: 768px) {
    .sect-pri header {
        font-size: 35px !important;
    }
    .sect-pri p {
        font-size: 16px;
    }
    .sect-pri .img img {
        width: 300px !important;
        height: auto;
    }
    .sect-pri button {
        font-size: 16px !important;
    }
    #titulo-imagen {
        width: 45px; 
        height: auto;
    }
}
/* Medio pequeño (para teléfonos móviles grandes) */
@media only screen and (min-width: 481px) and (max-width: 600px) {
    .sect-pri {
        height: 450px;
        position: relative;
        margin: 0;
        padding: 0;
        box-shadow: 0 0 55px black;
    }
    .sect-pri header {
        font-size: 65px;
    }
    .sect-pri .texto {
        gap: 30px;
        padding-left: 0px !important;
        font-size: 16px !important;
        display: flex !important;
        flex-direction: column !important; 
        justify-content: center !important;
        align-items: center !important;
        margin-left: 10px !important;
        margin-top: 30px !important;
        width: 100% !important;
    }
    .sect-pri p {
        width: 80% !important;
        font-size: 14px;
    }
    .sect-pri .img {
        display: none;
    }
    .sect-pri .img img {
        display: none;
    }
    #titulo-imagen {
        width: 45px; 
        height: auto;
    }
}
/* Pequeño (para teléfonos móviles pequeños) */
@media only screen and (min-width: 321px) and (max-width: 480px) {
    .sect-pri {
        height: 450px;
        position: relative;
        margin: 0;
        padding: 0;
        box-shadow: 0 0 55px black;
    }
    .sect-pri header {
        font-size: 65px;
    }
    .sect-pri .texto {
        gap: 30px;
        padding-left: 0px !important;
        font-size: 16px !important;
        display: flex !important;
        flex-direction: column !important; 
        justify-content: center !important;
        align-items: center !important;
        margin-left: 0px !important;
        margin-top: 30px !important;
    }
    .sect-pri p {
        width: 80% !important;
        font-size: 14px;
    }
    .sect-pri .img {
        display: none;
    }
    .sect-pri .img img {
        display: none;
    }
    #titulo-imagen {
        width: 45px; 
        height: auto;
    }
}
/* Extra pequeño (para teléfonos móviles muy pequeños) */
@media only screen and (max-width: 320px) {
    .sect-pri {
        height: 450px;
        position: relative;
        margin: 0;
        padding: 0;
        box-shadow: 0 0 55px black;
    }
    .sect-pri header {
        font-size: 65px;
    }
    .sect-pri .texto {
        gap: 30px;
        padding-left: 0px !important;
        font-size: 16px !important;
        display: flex !important;
        flex-direction: column !important; 
        justify-content: center !important;
        align-items: center !important;
        margin-left: 10px !important;
        margin-top: 30px !important;
    }
    .sect-pri p {
        width: 80% !important;
        font-size: 14px;
    }
    .sect-pri .img {
        display: none;
    }
    .sect-pri .img img {
        display: none;
    }
    #titulo-imagen {
        width: 45px; 
        height: auto;
    }
}

/* Segunda Seccion */
/* Medio (para retratos de tabletas y teléfonos móviles grandes) */
@media only screen and (min-width: 601px) and (max-width: 768px) {
    .header-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .sect-cuarta h1 {
        padding: 0;
    }
    #titulo-imagen {
        width: 65px; 
        height: auto;
    }
}
/* Medio pequeño (para teléfonos móviles grandes) */
@media only screen and (min-width: 481px) and (max-width: 600px) {
    .sect-cuarta .row .info {
        font-size: 16px;
        width: 100%;
    }
    .header-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .sect-cuarta h1 {
        font-size: 45px;
        padding: 0;
    }
    .sect-cuarta .row .imagenes {
        display: none;
    }
}
/* Pequeño (para teléfonos móviles pequeños) */
@media only screen and (min-width: 321px) and (max-width: 480px) {
    .sect-cuarta .row .info {
        font-size: 16px;
    }
    .header-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .sect-cuarta h1 {
        font-size: 40px;
        padding: 0;
    }
    .sect-cuarta .row .imagenes {
        display: none;
    }
}
/* Extra pequeño (para teléfonos móviles muy pequeños) */
@media only screen and (max-width: 320px) {
    .sect-cuarta .row .info {
        font-size: 16px;
    }
    .header-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .sect-cuarta h1 {
        font-size: 30px;
        padding: 0;
    }
    .sect-cuarta .row .imagenes {
        display: none;
    }
}

/* Tercera Seccion */
/* Medio (para retratos de tabletas y teléfonos móviles grandes) */
@media only screen and (min-width: 601px) and (max-width: 768px) {
    .sect-quinta .row .imagenes div:nth-child(2){
        width: 250px;
        margin-left: 15px;
    }
}
/* Medio pequeño (para teléfonos móviles grandes) */
@media only screen and (min-width: 481px) and (max-width: 600px) {
    .sect-quinta {
        margin-top: 0px;
    }
    .sect-quinta h3 {
        font-size: 24px;
    }
    .sect-quinta .row {
        padding: 0;
        margin: 0;
        width: 100%;
        height: auto;
    }
    .sect-quinta .row .info {
        font-size: 16px;
        width: 100%;
    }
    .sect-quinta .row .info h1 {
        font-size: 45px;
    }
    .sect-quinta .row .imagenes {
        display: none;
    }
}
/* Pequeño (para teléfonos móviles pequeños) */
@media only screen and (min-width: 321px) and (max-width: 480px) {
    .sect-quinta {
        margin-top: 0px;
    }
    .sect-quinta h3 {
        font-size: 22px;
    }
    .sect-quinta .row {
        padding: 0;
        margin: 0;
        width: 100%;
        height: auto;
    }
    .sect-quinta .row .info {
        font-size: 16px;
    }
    .sect-quinta .row .info h1 {
        font-size: 25px;
    }
    .sect-quinta .row .imagenes {
        display: none;
    }
}
/* Extra pequeño (para teléfonos móviles muy pequeños) */
@media only screen and (max-width: 320px) {
    .sect-quinta {
        margin-top: 0px;
    }
    .sect-quinta h3 {
        font-size: 22px;
    }
    .sect-quinta .row {
        padding: 0;
        margin: 0;
        width: 100%;
        height: auto;
    }
    .sect-quinta .row .info {
        font-size: 16px;
    }
    .sect-quinta .row .info h1 {
        font-size: 25px;
    }
    .sect-quinta .row .imagenes {
        display: none;
    }
}

/* Cuarta Seccion */
/* Medio pequeño (para teléfonos móviles grandes) */
@media only screen and (min-width: 481px) and (max-width: 600px) {
    .sect-col-firts, .sect-col-sec{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px;
        margin: 0px;
    }
    .sect-es .icono{
        font-size: 40px;
    }
    .sect-es .icono:hover{
        font-size: 65px;
    }
    .sect-es .texto{
        font-size: 12px;
    }
}
/* Pequeño (para teléfonos móviles pequeños) */
@media only screen and (min-width: 321px) and (max-width: 480px) {
    .sect-col-firts, .sect-col-sec{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px;
        margin: 0px;
    }
    .sect-es .icono{
        font-size: 50px;
    }
    .sect-es .icono:hover{
        font-size: 60px;
    }
    .sect-es .texto{
        font-size: 16px;
    }
}
/* Extra pequeño (para teléfonos móviles muy pequeños) */
@media only screen and (max-width: 320px) {
    .sect-col-firts, .sect-col-sec{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px;
        margin: 0px;
    }
    .sect-es .icono{
        font-size: 30px;
    }
    .sect-es .icono:hover{
        font-size: 40px;
    }
    .sect-es .texto{
        font-size: 12px;
    }
}

/* Quinta Seccion */
/* Medio grande (para tabletas en paisaje y algunos ordenadores de escritorio) */
@media only screen and (min-width: 769px) and (max-width: 992px) {
    .overlay{
        top: 35%;
        left: 50%;
        transform: translate(-50%, -160%);
        -webkit-transform: translate(-50%, -160%);
        -moz-transform: translate(-50%, -160%);
        -ms-transform: translate(-50%, -160%);
        -o-transform: translate(-50%, -160%);
    }
    .overlay p{
        font-size: 22px;
    }
    .card {
        min-height: 420px;
    }
}
/* Medio (para retratos de tabletas y teléfonos móviles grandes) */
@media only screen and (min-width: 601px) and (max-width: 768px) {
    .overlay{
        top: 35%;
        left: 50%;
        transform: translate(-50%, -200%);
        -webkit-transform: translate(-50%, -200%);
        -moz-transform: translate(-50%, -200%);
        -ms-transform: translate(-50%, -200%);
        -o-transform: translate(-50%, -200%);
}
    .overlay p{
        font-size: 16px;
    }
    .card {
        min-height: 400px;
    }
    .card-body .card-text {
        font-size: 14px;
    }
}
/* Medio pequeño (para teléfonos móviles grandes) */
@media only screen and (min-width: 481px) and (max-width: 600px) {
    .seccion-principios{
        width: 100%;
        min-height: 100% !important;
        padding: 20px;
        border-top: 2px solid #0CABAE;
        background-color: rgba(220, 220, 220, 0.2);
        margin-bottom: 400px;
    }
    .seccion-principios .caja {
        height: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex: 1;
    }
    .seccion-principios .titulo-an{
        font-size: 35px;
    }
    .overlay{
        top: 35%;
        left: 50%;
        transform: translate(-50%, -150%);
        -webkit-transform: translate(-50%, -150%);
        -moz-transform: translate(-50%, -150%);
        -ms-transform: translate(-50%, -150%);
        -o-transform: translate(-50%, -150%);
    }
    .overlay p{
        font-size: 20px;
    }
    .card{
        width: 46% !important;
        height: 400px;
    }
    .card-body{
        background-color: #2e2e2e;
        color: white;
        width: 100% !important;
    }
    .card-body .card-text {
        font-size: 14px;
    }
}
/* Pequeño (para teléfonos móviles pequeños) */
@media only screen and (min-width: 321px) and (max-width: 480px) {
    .seccion-principios{
        width: 100%;
        min-height: 100% !important;
        padding: 20px;
        border-top: 2px solid #0CABAE;
        background-color: rgba(220, 220, 220, 0.2);
        margin-bottom: 400px;
    }
    .seccion-principios .caja {
        height: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex: 1;
    }
    .seccion-principios .titulo-an{
        font-size: 25px;
    }
    .overlay{
        top: 35%;
        left: 50%;
        transform: translate(-50%, -200%);
        -webkit-transform: translate(-50%, -200%);
        -moz-transform: translate(-50%, -200%);
        -ms-transform: translate(-50%, -200%);
        -o-transform: translate(-50%, -200%);
}
    .overlay p{
        font-size: 20px;
    }
    .card{
        width: 46% !important;
        height: 400px;
    }
    .card-body{
        background-color: #2e2e2e;
        color: white;
        width: 100% !important;
    }
    .card-body .card-text {
        font-size: 14px;
    }
}
/* Extra pequeño (para teléfonos móviles muy pequeños) */
@media only screen and (max-width: 320px) {
    .seccion-principios{
        width: 100%;
        height: 100% !important;
        padding: 20px;
        border-top: 2px solid #0CABAE;
        background-color: rgba(220, 220, 220, 0.2);
        margin-bottom: 1400px;
    }
    .seccion-principios .caja {
        height: auto !important;
        display: flex;
        flex-direction: column;
    }
    .seccion-principios .titulo-an{
        font-size: 25px;
    }
    .overlay{
        top: 35%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .overlay p{
        font-size: 28px;
    }
    .card{
        width: 100% !important;
    }
    .card-body{
        background-color: #2e2e2e;
        color: white;
        width: 100% !important;
    }
    .card-body .card-text {
        font-size: 16px;
    }
}